
@media screen and (min-device-width: 989px){
  .rsp-label{
      font-size: 1rem !important;
   }
}

@media screen and (max-device-width: 989px){
  .rsp-label{
      font-size: 0.9rem !important;
   }
   .rsp-hide{
     display: none;
   }
}
