.__modal {
  z-index: 100;
  position: fixed;
  top: 14vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.__modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  color: black;
  border-radius: 8px;
}

.__modal__header h2 {
  margin: 0.5rem;
  text-align: center;
}

.__modal__content {
  padding: 1rem 0.5rem;
  text-align: center;
}

.__modal__footer {
  padding: 1rem 0.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .__modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.__modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.__modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.__modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.__modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
