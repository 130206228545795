@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
html {
  scroll-behavior: smooth;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(235, 235, 235, 0.9);
  z-index: -100;
}
.card {
  box-shadow: 7px 7px 28px -19px rgba(0, 0, 0, 0.43);
}

/* .form-control {
  -webkit-box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
  box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
} */
/* .navbar {
  -webkit-box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
} */
.logo {
  font-family: "Pacifico", cursive;
  color: #bec328 !important;
  font-size: 30px !important;
}
.loading-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box {
  height: 100vh;
}
.supplier-card-list-p {
  margin-bottom: 5px;
  font-size: 12px;
}
.supplier-new-btn {
  display: inline-block;
}
.newUser-accordion > .card-header {
  background-color: #0275d8;
}
.white-text {
  color: white;
  font-size: 1.25rem;
  line-height: 1.5;
}
.btn-primary.disabled {
  background-color: grey;
  border-color: grey;
}
.pdf-icon {
  height: 25px;
}
.white-bg {
  background-color: white;
}
.sticky-offset {
  top: 75px !important;
  z-index: 1 !important;
}
.hover-shadow {
  box-shadow: 0px -1px 0px 3px rgba(210, 210, 210, 1);
}
.btn-width {
  position: relative;
  left: -25px;
  width: 120px;
}
.test-status {
  position: relative;
  left: -10px;
}
.small-test-indicator-secondary {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #596267;
}
.small-test-indicator-success {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #218838;
}
.small-test-indicator-danger {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #dc3544;
}

.small-test-indicator-info {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #17a2b8;
}

.small-test-indicator-outline-info {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #17a2b8
}


.card-header {
  background-color: white !important;
}

.stealth-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.stealth-button:focus {
  outline: none;
}

#newUserToggle {
  background-color: #027bfe !important;
}
#changePasswordToggle {
  background-color: #027bfe !important;
}
#setVetToggle {
  background-color: #027bfe !important;
}

.btn-fixed-height{
  height: 100%;
}

.initial-value {
     -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 1s;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  /* margin: 60px auto; */
  text-align: center;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(255, 255, 255, 0.2);
  border-right: 0.2em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.2);
  border-left: 0.2em solid #ffffff;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

i{
  font-size: 20px;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
              animation: fadein 1.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  z-index: 10;
}

.__modal {
  z-index: 100;
  position: fixed;
  top: 14vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.__modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  color: black;
  border-radius: 8px;
}

.__modal__header h2 {
  margin: 0.5rem;
  text-align: center;
}

.__modal__content {
  padding: 1rem 0.5rem;
  text-align: center;
}

.__modal__footer {
  padding: 1rem 0.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .__modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.__modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.__modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.__modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.__modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.form-150-enter {
  max-height: 0;
}
.form-150-enter-active {
  overflow-y: hidden;
  max-height: 150px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-150-exit {
  max-height: 150px;
}
.form-150-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}

.form-300-enter {
  max-height: 0;
}
.form-300-enter-active {
  overflow-y: hidden;
  max-height: 300px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-300-exit {
  max-height: 300px;
}
.form-300-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}

.form-500-enter {
  max-height: 0;
}
.form-500-enter-active {
  overflow-y: hidden;
  max-height: 500px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-500-exit {
  max-height: 500px;
}
.form-500-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}



.tab {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 0;
  margin-right: 1rem;
  color: rgba(50, 50, 50, 0.6);
  border-bottom: 5px solid transparent
}

.tab:hover {
  border-bottom: 5px solid #007bff;
  color: #007bff;
}

.tab-active {
  border-bottom: 5px solid #007bff;
  color: #007bff;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #4d82be;
  border-color: #4d82be transparent #4d82be transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-select{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin-bottom: 15px;
}


@media screen and (min-device-width: 989px){
  .rsp-btn{
      margin-bottom: 0 !important;
   }
}

@media screen and (max-device-width: 989px){
  .rsp-btn{
      margin-bottom: 1rem !important;
   }
}

@media screen and (min-device-width: 989px){
  .form-group{
      margin-bottom: 0 !important;
   }
}

@media screen and (min-device-width: 989px){
  .btn-text{
      display: none !important;
   }
}

@media screen and (max-device-width: 989px){
  .btn-symbol{
      display: none !important;
   }
  .status-text{
     display: none !important
  }
}


@media screen and (min-device-width: 989px){
  .rsp-label{
      font-size: 1rem !important;
   }
}

@media screen and (max-device-width: 989px){
  .rsp-label{
      font-size: 0.9rem !important;
   }
   .rsp-hide{
     display: none;
   }
}

