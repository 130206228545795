.tab {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 0;
  margin-right: 1rem;
  color: rgba(50, 50, 50, 0.6);
  border-bottom: 5px solid transparent
}

.tab:hover {
  border-bottom: 5px solid #007bff;
  color: #007bff;
}

.tab-active {
  border-bottom: 5px solid #007bff;
  color: #007bff;
}
