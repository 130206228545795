@media screen and (min-device-width: 989px){
  .form-group{
      margin-bottom: 0 !important;
   }
}

@media screen and (min-device-width: 989px){
  .btn-text{
      display: none !important;
   }
}

@media screen and (max-device-width: 989px){
  .btn-symbol{
      display: none !important;
   }
  .status-text{
     display: none !important
  }
}
