@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

html {
  scroll-behavior: smooth;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(235, 235, 235, 0.9);
  z-index: -100;
}
.card {
  -webkit-box-shadow: 7px 7px 28px -19px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 7px 7px 28px -19px rgba(0, 0, 0, 0.43);
  box-shadow: 7px 7px 28px -19px rgba(0, 0, 0, 0.43);
}

/* .form-control {
  -webkit-box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
  box-shadow: 10px 10px 58px -19px rgba(0, 0, 0, 0.43);
} */
/* .navbar {
  -webkit-box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.09);
} */
.logo {
  font-family: "Pacifico", cursive;
  color: #bec328 !important;
  font-size: 30px !important;
}
.loading-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box {
  height: 100vh;
}
.supplier-card-list-p {
  margin-bottom: 5px;
  font-size: 12px;
}
.supplier-new-btn {
  display: inline-block;
}
.newUser-accordion > .card-header {
  background-color: #0275d8;
}
.white-text {
  color: white;
  font-size: 1.25rem;
  line-height: 1.5;
}
.btn-primary.disabled {
  background-color: grey;
  border-color: grey;
}
.pdf-icon {
  height: 25px;
}
.white-bg {
  background-color: white;
}
.sticky-offset {
  top: 75px !important;
  z-index: 1 !important;
}
.hover-shadow {
  -webkit-box-shadow: 0px -1px 0px 3px rgba(210, 210, 210, 1);
  -moz-box-shadow: 0px -1px 0px 3px rgba(210, 210, 210, 1);
  box-shadow: 0px -1px 0px 3px rgba(210, 210, 210, 1);
}
.btn-width {
  position: relative;
  left: -25px;
  width: 120px;
}
.test-status {
  position: relative;
  left: -10px;
}
.small-test-indicator-secondary {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #596267;
}
.small-test-indicator-success {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #218838;
}
.small-test-indicator-danger {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #dc3544;
}

.small-test-indicator-info {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #17a2b8;
}

.small-test-indicator-outline-info {
  position: relative;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #17a2b8
}


.card-header {
  background-color: white !important;
}

.stealth-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.stealth-button:focus {
  outline: none;
}

#newUserToggle {
  background-color: #027bfe !important;
}
#changePasswordToggle {
  background-color: #027bfe !important;
}
#setVetToggle {
  background-color: #027bfe !important;
}
