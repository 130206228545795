.form-150-enter {
  max-height: 0;
}
.form-150-enter-active {
  overflow-y: hidden;
  max-height: 150px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-150-exit {
  max-height: 150px;
}
.form-150-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}

.form-300-enter {
  max-height: 0;
}
.form-300-enter-active {
  overflow-y: hidden;
  max-height: 300px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-300-exit {
  max-height: 300px;
}
.form-300-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}

.form-500-enter {
  max-height: 0;
}
.form-500-enter-active {
  overflow-y: hidden;
  max-height: 500px;
  transition: max-height 400ms;
  transform: rotate(0deg);
}
.form-500-exit {
  max-height: 500px;
}
.form-500-exit-active {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 400ms;
  transform: rotate(0deg);
}


